import React from "react"

import { Layout, MainMenu, SEO } from "src/components"
import { PATHS } from "src/constants"

const MenuPage = props => {
  const { location } = props
  const activeNavLink = PATHS[location.pathname]

  return (
    <Layout activeNavLink={activeNavLink}>
      <SEO title="New Golden Dragon | Menu" />
      <MainMenu />
    </Layout>
  )
}

export default MenuPage
